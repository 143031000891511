import axios from "axios";

const SIMPLEHASH_API_KEY = "alwaysbcodin_sk_2c56e9ac-2568-457c-a349-f4db3eadd8df_edgn4betbf20tnm6";
const API_ENDPOINT = `https://api.simplehash.com/api/v0`;

const client = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 10000,
  headers: {
    'accept': 'application/json',
    'X-API-KEY': SIMPLEHASH_API_KEY
  }
})

export default class SimpleHashService {

  static async getNFTsForContract(contractID: string) {
    return await client.get(`/nfts/ethereum/${contractID}?limit=50`);
  }

  static async getOwnersByContract(contractID: string) {
    return await client.get(`/nfts/owners/ethereum/${contractID}?limit=50&count=1`);
  }

  static async getListingsByContract(contractID: string) {
    return await client.get(`/nfts/listings/ethereum/${contractID}?limit=50&marketplaces=opensea%2Cx2y2%2Clooksrare&order_by=price_asc`);
  }

  static async getActivityByContract(contractID: string) {
    return await client.get(`/nfts/transfers/ethereum/${contractID}?include_nft_details=1&order_by=timestamp_desc&limit=50`);
  }
  
  static async getNFTs(nftIds: string[]) {
    return await client.get(`/nfts/assets?nft_ids=${nftIds.join(',')}`);
  }
}
