import { Header } from "../components/layout/Header";
import React from "react";
import { Link } from "react-router-dom";

export const NewContractScreen2 = () => {
  return (
    <div className="screen home-screen">
      <Header />
      <h1 className="hero-text">More Info</h1>
      <div className="input">
        <input placeholder="Collection Name" />
      </div>
      <div className="input">
        <input placeholder="Symbol"/>
      </div>
      <div className="input">
        <input placeholder="Mint Count" />
      </div>
      <div className="input">
        <input placeholder="Max Supply" />
      </div>
      <div className="input">
        <input placeholder="Price"/>
      </div>
      <div>
        <Link to="/theming-form">
        <button className="continue">
            Continue
            <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
          </button>
      </Link>
      </div>
    </div>
  )
}
