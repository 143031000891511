import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { HomeScreen } from "./screens/HomeScreen";
import { NewContractScreen } from "./screens/NewContractScreen";
import { CollectionShowScreen} from "./screens/CollectionShowScreen";
import { CollectionStatusScreen } from "./screens/CollectionStatusScreen";
import { NewContractScreen2 } from "./screens/NewContractScreen2";
import { ThemingFormScreen } from "./screens/ThemingFormScreen";
import { SocialsFormScreen } from "./screens/SocialsFormScreen";
import { Template } from "./template/Template";
import { URIStatusScreen } from "./screens/URIStatusScreen";
import { Sitemap } from "./components/layout/Sitemap";

export const App = () => {

  return (
    <div className="Container">
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/collection-show" element={<CollectionShowScreen />} />
            <Route path="/collection-status" element={<CollectionStatusScreen />} />
            <Route path="/uri-status" element={<URIStatusScreen />} />
            <Route path="/contract-form" element={<NewContractScreen />} />
            <Route path="/contract-form2" element={<NewContractScreen2 />} />
            <Route path="/theming-form" element={<ThemingFormScreen />} />
            <Route path="/socials-form" element={<SocialsFormScreen/>} />
            <Route path="/template" element={<Template/>} />
          </Routes>
        </Router>
      </div>
    </div>
  )

}