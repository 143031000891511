import React from "react";

interface CollectibleListingIndexProps {
  imageURI: string;
  displayName: string;
  listingPrice: string;
  listingPriceCurrency: string;
  listingPriceUSD: string;
  listingMarketplace: string;
}

export const CollectibleListingIndex = (props: CollectibleListingIndexProps) => {

  return (
    <div className="collectible-listing-index">
      <img className="nft-image" src={props.imageURI} />
      <p className="collectible-name">{props.displayName}</p>
      <div className="listing-price">
        <p className="listing-price-amount">{props.listingPrice}</p>
        <p className="listing-price-currency">{props.listingPriceCurrency}</p>
        <p className="listing-price-usd">({props.listingPriceUSD})</p>
      </div>
      <div className="flex-spacer" />
      <div className="bottom-actions">
        <p className="buy-now">Buy Now</p>
        <div className="flex-spacer" />
        <p className="listing-marketplace">{props.listingMarketplace}</p>
      </div>
    </div>
  )

}