import React from "react";
import { TemplateHeader } from "./components/TemplateHeader";
import { TemplateSidebar } from "./components/TemplateSidebar";

export const Template = () => {
  return (
    <div className="template-container">
        <TemplateHeader />
        <TemplateSidebar />
      

    </div>
  )
}
