import { HeroHeader } from "../components/layout/HeroHeader";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const HomeScreen = () => {

  useEffect(() => {
    const nav = document.getElementById("nav");

    for(const link of nav.getElementsByTagName("button")) {  
      link.onmousemove = e => {
        const rect = link.getBoundingClientRect(),    
              img = link.querySelector("section");
        
        img.style.left = `${(e.clientX - rect.left) * 3}px`;
        img.style.top = `${(e.clientY - rect.top) * 2}px`
      }
    }
  }, [])

  const [state1, setState1] = useState(true);
  const [state2, setState2] = useState(true);
  const [state3, setState3] = useState(true);
  const [state4, setState4] = useState(true);
  const [state5, setState5] = useState(true);

  const button1Handler = () => {
    setState1(current => !current)
    console.log(state1) 
  }
  const button2Handler = () => {
    setState2(current => !current)
    console.log(state2) 
  }
  const button3Handler = () => {
    setState3(current => !current)
    console.log(state3)  
  }
  const button4Handler = () => {
    setState4(current => !current)
    console.log(state4) 
  }
  const button5Handler = () => {
    setState5(current => !current)
    console.log(state5) 
  }


  return (
    <>
      <div id="section2" className="screen home-screen">      
      <HeroHeader />    
        
        
        
        <h3 className="hero-text">Create, <br /> We'll handle the rest.</h3>
        <h4>
          Artisyn is the best end to end, no code solution for NFT <span>creators </span>to construct, deploy, and mint their collections.
          </h4>

        <Link className="get-started grow" to="/collection-status">
            Get Started
            <svg height="5vw" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
            </svg>
        </Link>

        
        <nav id="nav">
        <h1>
          SERVICES:
        </h1>
          <button onClick={button1Handler}>
            <span>Contract Deployment</span>
            <section className="hover">
              { state1 ?
                <img alt="pic" src="https://i.seadn.io/gcs/files/a32401721f12bd796fa6f694d5dc7ee8.png?auto=format&w=1000" />
              :
              <div>
                Build your NFT smart contract with no code
              </div> 
            }


            </section>
          </button>
          <button onClick={button2Handler}>
            <span>Collection Minting</span>
            <section className="hover">
              { state2 ?
                <img alt="pic" src="https://ipfs.io/ipfs/QmNf1UsmdGaMbpatQ6toXSkzDpizaGmC9zfunCyoz1enD5/penguin/5445.png" />
              :
              <div>
                Generate a mint page instantly
              </div> 
            }
            </section>
          </button>
          <button onClick={button3Handler}>
            <span>Marketplace</span>
            <section className="hover">
              { state3 ?
                <img alt="pic" src="https://www.protocol.com/media-library/cryptopunk-7804.png?id=25817821&width=980" />
              :
              <div>
                Aggregate your colletions listings
              </div> 
            }
            </section>
          </button>
          <button onClick={button4Handler}>
            <span>Analytics</span>
            <section className="hover">
              { state4 ?
                <img alt="pic" src="https://www.coindesk.com/resizer/oOFkMlEEC3pUp5W7luXgmD3-pF8=/567x425/filters:quality(80):format(jpg)/cloudfront-us-east-1.images.arcpublishing.com/coindesk/NC3B5PJLPFGTJEWNTOIE7EQJNM.jpg" />
              :
              <div>
                See live data on any collection
              </div> 
            }
            </section>
          </button>
          <button onClick={button5Handler}>
            <span>Community Building</span>
            <section className="hover">
              { state5 ?
                <img alt="pic" src="https://pbs.twimg.com/media/FqNtt6AXgAA8-q6?format=jpg&name=large" />
                :
                <div>
                  Start growing your community today
                </div> 
              }
            </section>
          </button>
        </nav>
        <a className="get-started grow" href="mailto:team@artisyn.ai?subject = Feedback&body = Message">
            Contact Us
            <svg height="5vw" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
            </svg>
        </a>
      </div>
    </>
  )
}
