import React from "react";

export const HeroHeader = () => {

  return (
    <div className="HeroHeader">
      <div className="logo">
        <a href="/">
          <svg height="3vw" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="20" width="1" height="40" fill="#6B81E5"/>
          <rect x="40" y="19" width="1" height="40" transform="rotate(90 40 19)" fill="#6B81E5"/>
          <rect x="34.1421" y="5.85785" width="1" height="40" transform="rotate(45 34.1421 5.85785)" fill="#6B81E5"/>
          <rect x="34.8491" y="33.435" width="1" height="40" transform="rotate(135 34.8491 33.435)" fill="#6B81E5"/>
          </svg>

          <h2 className="logo">
          ARTISYN
          </h2>
          
        </a>
      </div>

    </div>
  )
}