import React from "react";
import { Link, useLocation } from "react-router-dom";

export const TemplateHeader = () => {
  const location = useLocation();

  const NAVIGATION_LINKS = [
    {
      path: "",
      display: "Mint"
    },
    {
      path: "",
      display: "Trade"
    }
  ];

  return (
    <div className="template-header">
      <div className="template-logo">
        <a href="/">
          <h2>
          Template
          </h2>
          
        </a>
      </div>
      <div className="template-navigation-links">
        {NAVIGATION_LINKS.map((navigationLink) => {
          const navigationLinkClassName = location.pathname === navigationLink.path ? "template-navigation-link selected" : "template-navigation-link";
          return (
            <Link
              key={navigationLink.display}
              to={navigationLink.path}
              className={navigationLinkClassName}>
              {navigationLink.display}
            </Link>
          )
        })}
      </div>
    </div>
  )

}