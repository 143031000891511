import React, { useState } from "react";
import SimpleHashService from "../services/SimpleHashService";

import { CollectibleListingIndex } from "../components/collectibleListingIndex";
import { Header } from "../components/layout/Header";

export const CollectionShowScreen = () => {

  const [contractAddress, setContractAddress] = useState("");
  const [contractData, setContractData] = useState([]);
  const [ownersResponse, setOwnersResponse] = useState(undefined);
  const [listingsData, setListingsData] = useState([]);
  const [nftsData, setNFTsData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const getContractData = async () => {
    // const nftsResponse = await SimpleHashService.getNFTsForContract(contractAddress);
    // const ownersResponse = await SimpleHashService.getOwnersByContract(contractAddress);
    // const activityResponse = await SimpleHashService.getActivityByContract(contractAddress);
    // setContractData(nftsResponse.data.nfts);
    // setOwnersResponse(ownersResponse.data);
    // setActivityData(activityResponse.data);

    const listingsResponse = await SimpleHashService.getListingsByContract(contractAddress);
    setListingsData(listingsResponse.data.listings as any[]);

    const nftIds = listingsResponse.data.listings.map(listing => listing.nft_id as string);
    const nftsResponse = await SimpleHashService.getNFTs(nftIds);
    setNFTsData(nftsResponse.data.nfts);

  }

  return (
    <div className="screen collection-show-screen">
      <Header />
      <h1 className="hero-text">See Collection</h1>
      <div className="address-input">
        <input
          className="address-input-text"
          value={contractAddress}
          onChange={(e) => { setContractAddress(e.target.value) }}
          placeholder="Enter Contract Address:"/>

      </div>

      <button className="continue" onClick={getContractData}>
        Submit
        <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
          </svg>
      </button>
      {nftsData.length > 0 ? (
        <div className="banner">
        </div>
      ) : (<></>)}

      <div className="listings">
        {listingsData.map((listing, index) => {

          const nftData = nftsData.find(nftData => nftData.nft_id === listing.nft_id);
          return (
            <CollectibleListingIndex
              key={`collectible-listing-${index}`}
              imageURI={nftData ? nftData.image_url : ""}
              displayName={nftData ? nftData.name : ""}
              listingPrice={`${listing.price / Math.pow(10, listing.payment_token.decimals)}`}
              listingPriceCurrency={listing.payment_token.symbol}
              listingPriceUSD=""
              listingMarketplace={listing.marketplace_id} />
          )
        })}
      </div>
      {/* {ownersResponse && ownersResponse.owners.length > 0 ? (
        <div className="holders">
          <div className="holder-section">
            <p className="total-nfts">
              {ownersResponse.distinct_nft_count}
            </p>
            <p className="total-nfts label">
              Total NFTs
            </p>
          </div>
          <div className="holder-section">
            <p className="unique-owners">
              {ownersResponse.count}
            </p>
            <p className="unique-owners">
              Unique Owners
            </p>
          </div>
        </div>
      ) : (<></>)} */}
    </div>
  )
}