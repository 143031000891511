import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Sitemap = () => {
  const location = useLocation();

  const NAVIGATION_LINKS = [
    {
      path: "/",
      display: "Home"
    },
    {
      path: "/collection-show",
      display: "collection-show"
    },
    {
      path: "/collection-status",
      display: "collection-status"
    },
    {
      path: "/uri-status",
      display: "uri-status"
    },
    {
      path: "/contract-form",
      display: "New Contract Form"
    },
    {
      path: "/contract-form2",
      display: "New Contract Form 2"
    },
    {
      path: "/theming-form",
      display: "theming-form"
    },
    {
      path: "/socials-form",
      display: "socials-form"
    },
    {
      path: "/template",
      display: "Template"
    }
  ];

  return (
    <div className="sitemap">
      <div className="Logo">
        <a href="/">
          <h2 className="logo">
          ARTISYN
          </h2>
          
        </a>
      </div>
      <div className="navigation-links">
        {NAVIGATION_LINKS.map((navigationLink) => {
          const navigationLinkClassName = location.pathname === navigationLink.path ? "navigation-link selected" : "navigation-link";
          return (
            <Link
              key={navigationLink.display}
              to={navigationLink.path}
              className={navigationLinkClassName}>
              {navigationLink.display}
            </Link>
          )
        })}
      </div>

    </div>
  )
}