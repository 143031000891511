import { Header } from "../components/layout/Header";
import React from "react";
import { Link } from "react-router-dom";
import ColorPicker from "../components/utils/ColorPicker"


export const ThemingFormScreen = () => {
  return (
    <div className="screen home-screen">
      <Header />
      <h1 className="hero-text">Theming Info</h1>
      <div className="input">
        <input placeholder="Collection Name" />
      </div>
      <div className="input">
        <input placeholder="Symbol"/>
      </div>
      <div className="input">
        <div>
          Primary Color
          <ColorPicker />
        </div>
      </div>
      <div className="input">
        <div>
          Secondary Color
          <ColorPicker />
        </div>
      </div>
      <div className="input">
        <div>
          Tertiary Color
          <ColorPicker />
        </div>
      </div>

      <div>

        <Link to="/socials-form">
        <button className="continue">
            Continue
            <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
          </button>
      </Link>
      </div>
    </div>
  )
}
