import { Header } from "../components/layout/Header";
import React from "react";
import { Link } from "react-router-dom";

export const URIStatusScreen = () => {
  return (
    <div className="screen home-screen">
      <Header />
      <h1 className="hero-text">Is your nft art deployed to a hosting service?</h1>
      <input placeholder="Base URI" />
      <div className="yes-no">
      <Link to="/contract-form">
        <button className="continue">
          YES
          <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
        </button>
      </Link>
      <Link to="https://www.pinata.cloud/" target="_blank">
        <button className="continue">
          NO
          <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
        </button>
      </Link>
      </div>
    </div>
  )
}
