import { FC } from 'react'
import { useAccount } from 'wagmi'
import { ConnectKitButton } from 'connectkit'

type Visibility = 'always' | 'connected' | 'not_connected'

const ConnectWallet: FC<{ show?: Visibility }> = ({ show = 'always' }) => {
	const { isConnected } = useAccount()

	if ((show === 'connected' && !isConnected) || (show === 'not_connected' && isConnected)) return null

	return (
		<ConnectKitButton.Custom>
		{({ isConnected, show, truncatedAddress, ensName }) => {
			return (
			<button className="connect-button" onClick={show}>
				{isConnected ? ensName ?? truncatedAddress : "CONNECT"}
				<svg height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
			</button >
			);
		}}
		</ConnectKitButton.Custom>
	);
}

export default ConnectWallet
