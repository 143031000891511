import { Header } from "../components/layout/Header";
import React from "react";

export const SocialsFormScreen = () => {
  return (
    <div className="screen home-screen">
      <Header />
      <h1 className="hero-text">Socials Info</h1>
      <div className="input">
        <input placeholder="Social 1" />
      </div>
      <div className="input">
        <input placeholder="link"/>
      </div>
      <div className="input">
        <input placeholder="Social 2" />
      </div>
      <div className="input">
        <input placeholder="link" />
      </div>
      <div className="social 3">
        <input placeholder="Price"/>
      </div>
      <div className="social 3">
        <input placeholder="link"/>
      </div>
      <div>
      <button className="continue">
            Continue
            <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
          </button>
      </div>
    </div>
  )
}
