import { Header } from "../components/layout/Header";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export const NewContractScreen = () => {

  const [collectionName, setCollectionName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [mintCount, setMintCount] = useState("");
  const [maxSupply, setMaxSupply] = useState("");
  const [mintersPerAddress, setMintersPerAddress] = useState("");
  const [mintPrice, setMintPrice] = useState("");

  return (
    
  <>
    <div className="screen home-screen">
      <Header />
      <h1 className="hero-text">Contract Information</h1>
      <div className="input">
        <input 
          placeholder="Collection Name"
          value={collectionName}
          onChange={(e) => { setCollectionName(e.target.value) }}
         />
      </div>
      <div className="input">
        <input 
          placeholder="Symbol"
          value={symbol}
          onChange={(e) => { setSymbol(e.target.value) }}
        />
      </div>
      <div className="input">
        <input 
          placeholder="Mint Count" 
          value={mintCount}
          onChange={(e) => { setMintCount(e.target.value) }}
        />
      </div>
      <div className="input">
        <input 
          placeholder="Max Supply" 
          value={maxSupply}
          onChange={(e) => { setMaxSupply(e.target.value) }}
        />
      </div>
      <div className="input">
        <input 
          placeholder="Minters Per Address"
          value={mintersPerAddress}
          onChange={(e) => { setMintersPerAddress(e.target.value) }}
        />
      </div>
      <div className="input">
        <input 
          placeholder="Mint Price"
          value={mintPrice}
          onChange={(e) => { setMintPrice(e.target.value) }}
        />
      </div>
      <div>
        <Link to="/contract-form2">
          <button className="continue">
            Continue
            <svg height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              		<path d="M1 17L17 1M17 1H1M17 1V17" stroke="currentColor"/>
              	</svg>
          </button>
        </Link>
      </div>
      
    </div>
  </>
  )
}
